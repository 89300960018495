.exclusive {
    width: 100%;
    max-width: 900px;
    margin: 10px auto;
    padding: 60px;
    padding-top: 0px;
    border-radius: 8px;
    box-shadow: 0 0 0.4rem #060606;
    background-position: center;
    background-size: cover;
    background-color: #538EB6;
      
  }
  
  .exclusive-section {
    border-radius: 6px;
    text-align: left;
  }
  
 
  
  .exclusive-section .text h1 {
    font-size: 32px;
    color: white;
  }
  
  .exclusive-section .text h3 {
    margin: 10px 0;
    font-weight: 300;
    font-size: 25px;
    color: white;
  }
  
  .exclusive-section form {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    display: grid;
    grid-template-columns: 40% 40% 20%;
    align-items: center;
    text-align: left;
  }
  
  .exclusive-section form .exclusive-input {
    width: 95%;
    height: 50px;
    padding: 0 15px;
    font-size: 16px;
    color: #fff;
  }
  
  .exclusive-section form .exclusive-input + .exclusive-input {
    height: 50px;
    font-size: 16px;
    color: #fff;
  }
  
  .exclusive-section form .exclusive-button {
    width: 100%;
    height: 50px;
    color: #fff;
    background: transparent;
    border-radius: 0.4rem;
    padding: 0.4rem 1.5rem;
    border: 0.1rem solid #fff;
    font-size: 1.1em;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease-in;
  }
  
  .exclusive-section form .exclusive-button:hover {
    border: 0.1rem solid transparent;
  }
  
  @media (max-width: 700px) {
    .exclusive {
      width: 100%;
      max-width: fit-content;
      margin: 50px 10px;
      padding: 20px;
    }
  
    .exclusive-section {
      width: 100%;
      margin: 0 auto;
      max-width: 500px;
      text-align: left;
    }
  
    .exclusive-section form {
      width: 100%;
      padding: 10px;
      display: table-row;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .exclusive-section form .exclusive-input {
      width: 100%;
      margin-bottom: 1rem;
    }
  
    .exclusive-section form .exclusive-input + .exclusive-input {
      height: 50px;
      font-size: 16px;
      color: var(--_color_2);
    }
  
    .exclusive-section .text {
      width: 100%;
      max-width: 500px;
    }
  }
  