.logo-main {
    display: flex;
    align-items: center;
    padding-right: 10px;
    text-decoration: none;
}

.logo-new {
    width: 90px;
    height: 90px;
    padding: 5px 10px;
}


.custom-ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.custom-ul li {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 0.9rem;
    margin: 0;
}

.custom-ul li .btn-header {
    text-decoration: none;
    cursor: pointer;
    font-weight: 900;
    color: #fff;
    padding: 5px 0;
    transition: 0.5s;
    font-size: 1em;
    transition: 0.1s ease-in;
    border-bottom: none;
    
}

.custom-ul li .btn-header:hover {
    border-bottom: 2px solid #fff;
}

.custom-ul li .btn-blog {
    background: transparent;
    color: #fff;
    /* border-radius: 0.4rem; */
    padding: 0.5rem 1rem;
    transition: 0.5s;
    font-size: 0.9em;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s ease-in;
    border-color: black;
    border-width: 2px;
    border-style: solid;
  border-width: thin;
}

.custom-ul li .btn-blog:hover {
    background: #34a500;
}

.spacer {
    flex: 1;
}