.zi-1 {
    z-index: 1!important;
}

.list-checked {
    padding-left: 0;
    list-style: none;
}

.list-checked-item {
    position: relative;
    color: #677788;
    padding-left: 2rem;
}

.list-checked-item-white {
    position: relative;
    color: #677788;
    padding-left: 2rem;
}

.list-checked-item::before {
    margin-top: 0.25rem;
}

.btn-ghost-primary {
    color: #377dff;
    background-color: transparent;
}


.list-checked-item::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23377dff'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.25rem 1.25rem;
    content: '';
    margin-top: 0.125rem;
}

.list-checked-item-white::before {
    position: absolute;
    margin-top: 0.25rem;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1.25rem 1.25rem;
    content: '';
    margin-top: 0.125rem;
    background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f7faff' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.card-footer {
    padding: 2rem 2rem;
    background-color: transparent;
    border-top: 0.0625rem solid rgba(33,50,91,.1);
}