.sub-admin .container {
    padding: 16px;
    background-color: white;
  }
  
  /* Full-width input fields */
  .sub-admin input[type=text], input[type=password] {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    background: #f1f1f1;
  }
  
  .sub-admin input[type=text]:focus, input[type=password]:focus {
    background-color: #ddd;
    outline: none;
  }
  
  /* Overwrite default styles of hr */
  .sub-admin hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }
  
  /* Set a style for the submit button */
  .sub-admin .registerbtn {
    background-color: #04AA6D;
    color: white;
    padding: 16px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
  }
  
  .sub-admin .registerbtn:hover {
    opacity: 1;
  }
  
  /* Add a blue text color to links */
  .sub-admin a {
    color: dodgerblue;
  }
  
  /* Set a grey background color and center the text of the "sign in" section */
  .sub-admin .signin {
    background-color: #f1f1f1;
    text-align: center;
  }