.modal.modal-fullscreen .modal-dialog {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    max-width: none; 
  }
  
  .modal.modal-fullscreen .modal-content {
    height: auto;
    height: 100vh;
    border-radius: 0;
    border: none; 
  }
  
  .modal.modal-fullscreen .modal-body {
    overflow-y: auto; 
  }