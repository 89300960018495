.custom-editor p{
    font-weight: normal;
    color: black;
}

.custom-editor li{
    font-weight: normal;
    color: black;
}

iframe {
    width: 100%;
    height: 400px;
}
